import * as React from "react";
import CardClientes from "./cardClientes";
import { Grid2 } from "@mui/material";
import { useLocation } from "react-router-dom";
import Footer from "./footer";
import { db } from "../config/firebaseConfig";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useEffect } from "react";

export default function ListaC() {
  const location = useLocation();

  const title = location.state.title;
  const tipo = location.state.tipo;

  const [publicidad, setPublicidad] = React.useState([
    ]);

  useEffect(() => {
    const collectionRef = collection(db, "Publicidad");
    const q = query(
      collectionRef,
      where("lugar", "==", title),
      where("tipo", "==", tipo)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        console.log("Datos obtenidos");
        
      setPublicidad(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          cliente: doc.data().cliente,
          resumen: doc.data().resumen,
          detalles: doc.data().detalles,
          imagen: doc.data().imagen,
          estrellas: doc.data().estrellas,
          prioridad: doc.data().prioridad,
          abrido: doc.data().abrido,
          horario: doc.data().horario,
          direccion: doc.data().direccion,
          telefono: doc.data().telefono,
          galeria: doc.data().galeria,
        }))
      );
    });
    
    return unsubscribe;
  }, []);

  console.log(publicidad);

  return (
    <>
      <Grid2
        sx={{ padding: 5, backgroundColor: "#353535" }}
        container
        spacing={5}
        columns={32}
      >
        {publicidad.map((item) => (
            <Grid2 size={16}>
                <CardClientes cliente={item.cliente} imagen={item.imagen} resumen={item.resumen} detalles={item.detalles} />
            </Grid2>
        ))}
      </Grid2>
      <Footer />
    </>
  );
}
